import { subscribeKey as subKey } from 'valtio/vanilla/utils';
import { proxy, ref, subscribe as sub } from 'valtio/vanilla';
const state = proxy({});
export const SendController = {
  state,
  subscribe(callback) {
    return sub(state, () => callback(state));
  },
  subscribeKey(key, callback) {
    return subKey(state, key, callback);
  },
  setToken(token) {
    if (token) {
      state.token = ref(token);
    }
  },
  setTokenAmount(sendTokenAmount) {
    state.sendTokenAmount = sendTokenAmount;
  },
  setReceiverAddress(receiverAddress) {
    state.receiverAddress = receiverAddress;
  },
  setReceiverProfileImageUrl(receiverProfileImageUrl) {
    state.receiverProfileImageUrl = receiverProfileImageUrl;
  },
  setReceiverProfileName(receiverProfileName) {
    state.receiverProfileName = receiverProfileName;
  },
  resetSend() {
    state.token = undefined;
    state.sendTokenAmount = undefined;
    state.receiverAddress = undefined;
    state.receiverProfileImageUrl = undefined;
    state.receiverProfileName = undefined;
  }
};
