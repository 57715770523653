import BigNumber from 'bignumber.js';
export const NumberUtil = {
  bigNumber(value) {
    return new BigNumber(value);
  },
  multiply(a, b) {
    if (a === undefined || b === undefined) {
      return BigNumber(0);
    }
    const aBigNumber = new BigNumber(a);
    const bBigNumber = new BigNumber(b);
    return aBigNumber.multipliedBy(bBigNumber);
  }
};
