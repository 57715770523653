import { subscribeKey as subKey } from 'valtio/utils';
import { proxy, subscribe as sub } from 'valtio/vanilla';
import { AccountController } from './AccountController.js';
import { ConstantsUtil } from '../utils/ConstantsUtil.js';
import { ConnectionController } from './ConnectionController.js';
import { ConvertApiUtil } from '../utils/ConvertApiUtil.js';
import { SnackController } from './SnackController.js';
import { RouterController } from './RouterController.js';
import { NumberUtil } from '@web3modal/common';
import { NetworkController } from './NetworkController.js';
import { CoreHelperUtil } from '../utils/CoreHelperUtil.js';
import { BlockchainApiController } from './BlockchainApiController.js';
import { OptionsController } from './OptionsController.js';
export const INITIAL_GAS_LIMIT = 150000;
class TransactionError extends Error {
  constructor(message, shortMessage) {
    super(message);
    this.name = 'TransactionError';
    this.shortMessage = shortMessage;
  }
}
const state = proxy({
  initialized: false,
  loading: false,
  loadingPrices: false,
  approvalTransaction: undefined,
  convertTransaction: undefined,
  transactionError: undefined,
  transactionLoading: false,
  sourceToken: undefined,
  sourceTokenAmount: '',
  sourceTokenPriceInUSD: 0,
  toToken: undefined,
  toTokenAmount: '',
  toTokenPriceInUSD: 0,
  networkPrice: '0',
  networkBalanceInUSD: '0',
  inputError: undefined,
  slippage: ConstantsUtil.CONVERT_SLIPPAGE_TOLERANCE,
  tokens: undefined,
  popularTokens: undefined,
  suggestedTokens: undefined,
  foundTokens: undefined,
  myTokensWithBalance: undefined,
  tokensPriceMap: {},
  gasFee: BigInt(0),
  gasPriceInUSD: 0,
  priceImpact: undefined,
  maxSlippage: undefined
});
export const ConvertController = {
  state,
  subscribe(callback) {
    return sub(state, () => callback(state));
  },
  subscribeKey(key, callback) {
    return subKey(state, key, callback);
  },
  getParams() {
    const {
      address
    } = AccountController.state;
    const networkAddress = `${NetworkController.state.caipNetwork?.id}:${ConstantsUtil.NATIVE_TOKEN_ADDRESS}`;
    if (!address) {
      throw new Error('No address found to swap the tokens from.');
    }
    return {
      networkAddress,
      fromAddress: address,
      fromCaipAddress: AccountController.state.caipAddress,
      sourceTokenAddress: state.sourceToken?.address,
      toTokenAddress: state.toToken?.address,
      toTokenAmount: state.toTokenAmount,
      toTokenDecimals: state.toToken?.decimals,
      sourceTokenAmount: state.sourceTokenAmount,
      sourceTokenDecimals: state.sourceToken?.decimals
    };
  },
  setLoading(loading) {
    state.loading = loading;
  },
  setSourceToken(sourceToken) {
    if (!sourceToken) {
      return;
    }
    state.sourceToken = sourceToken;
    this.setTokenValues(sourceToken.address, 'sourceToken');
  },
  setSourceTokenAmount(amount) {
    const {
      sourceTokenAddress
    } = this.getParams();
    state.sourceTokenAmount = amount;
    if (sourceTokenAddress) {
      this.setTokenValues(sourceTokenAddress, 'sourceToken');
    }
  },
  setToToken(toToken) {
    const {
      sourceTokenAddress,
      sourceTokenAmount
    } = this.getParams();
    if (!toToken) {
      state.toTokenAmount = '0';
      state.toTokenPriceInUSD = 0;
      return;
    }
    state.toToken = toToken;
    this.setTokenValues(toToken.address, 'toToken');
    if (sourceTokenAddress && sourceTokenAmount) {
      this.makeChecks();
    }
  },
  setToTokenAmount(amount) {
    const {
      toTokenAddress
    } = this.getParams();
    state.toTokenAmount = amount;
    if (toTokenAddress) {
      this.setTokenValues(toTokenAddress, 'toToken');
    }
  },
  async setTokenValues(address, target) {
    let price = state.tokensPriceMap[address] || 0;
    if (!price) {
      price = await this.getAddressPrice(address);
    }
    if (target === 'sourceToken') {
      state.sourceTokenPriceInUSD = price;
    } else if (target === 'toToken') {
      state.toTokenPriceInUSD = price;
    }
  },
  switchTokens() {
    const newSourceToken = state.toToken ? {
      ...state.toToken
    } : undefined;
    const newToToken = state.sourceToken ? {
      ...state.sourceToken
    } : undefined;
    this.setSourceToken(newSourceToken);
    this.setToToken(newToToken);
    this.setSourceTokenAmount(state.toTokenAmount || '0');
    ConvertController.convertTokens();
  },
  resetTokens() {
    state.tokens = undefined;
    state.popularTokens = undefined;
    state.myTokensWithBalance = undefined;
    state.initialized = false;
  },
  resetValues() {
    const {
      networkAddress
    } = this.getParams();
    const networkToken = state.tokens?.find(token => token.address === networkAddress);
    this.setSourceToken(networkToken);
    state.sourceTokenPriceInUSD = state.tokensPriceMap[networkAddress] || 0;
    state.sourceTokenAmount = '0';
    this.setToToken(undefined);
    state.gasPriceInUSD = 0;
  },
  clearError() {
    state.transactionError = undefined;
  },
  async initializeState() {
    if (!state.initialized) {
      await this.fetchTokens();
      state.initialized = true;
    }
  },
  async fetchTokens() {
    const {
      networkAddress
    } = this.getParams();
    await this.getTokenList();
    await this.getNetworkTokenPrice();
    await this.getMyTokensWithBalance();
    const networkToken = state.tokens?.find(token => token.address === networkAddress);
    if (networkToken) {
      this.setSourceToken(networkToken);
    }
  },
  async getTokenList() {
    const tokens = await ConvertApiUtil.getTokenList();
    state.tokens = tokens;
    state.popularTokens = tokens.sort((aTokenInfo, bTokenInfo) => {
      if (aTokenInfo.symbol < bTokenInfo.symbol) {
        return -1;
      }
      if (aTokenInfo.symbol > bTokenInfo.symbol) {
        return 1;
      }
      return 0;
    }).filter(token => {
      if (ConstantsUtil.POPULAR_TOKENS.includes(token.symbol)) {
        return true;
      }
      return false;
    }, {});
    state.suggestedTokens = tokens.filter(token => {
      if (ConstantsUtil.SUGGESTED_TOKENS.includes(token.symbol)) {
        return true;
      }
      return false;
    }, {});
  },
  async getAddressPrice(address) {
    const existPrice = state.tokensPriceMap[address];
    if (existPrice) {
      return existPrice;
    }
    const response = await BlockchainApiController.fetchTokenPrice({
      projectId: OptionsController.state.projectId,
      addresses: [address]
    });
    const fungibles = response.fungibles || [];
    const allTokens = [...(state.tokens || []), ...(state.myTokensWithBalance || [])];
    const symbol = allTokens?.find(token => token.address === address)?.symbol;
    const price = fungibles.find(p => p.symbol === symbol)?.price || '0';
    const priceAsFloat = parseFloat(price);
    state.tokensPriceMap[address] = priceAsFloat;
    return priceAsFloat;
  },
  async getNetworkTokenPrice() {
    const {
      networkAddress
    } = this.getParams();
    const response = await BlockchainApiController.fetchTokenPrice({
      projectId: OptionsController.state.projectId,
      addresses: [networkAddress]
    });
    const token = response.fungibles?.[0];
    const price = token?.price || '0';
    state.tokensPriceMap[networkAddress] = parseFloat(price);
    state.networkPrice = price;
  },
  async getMyTokensWithBalance() {
    const balances = await ConvertApiUtil.getMyTokensWithBalance();
    if (!balances) {
      return;
    }
    await this.getInitialGasPrice();
    this.setBalances(balances);
  },
  setBalances(balances) {
    const {
      networkAddress
    } = this.getParams();
    const networkToken = balances.find(token => token.address === networkAddress);
    balances.forEach(token => {
      state.tokensPriceMap[token.address] = token.price || 0;
    });
    state.myTokensWithBalance = balances;
    state.networkBalanceInUSD = networkToken ? NumberUtil.multiply(networkToken.quantity.numeric, networkToken.price).toString() : '0';
  },
  async getInitialGasPrice() {
    const res = await ConvertApiUtil.fetchGasPrice();
    if (!res) {
      return;
    }
    const value = res.instant;
    const gasFee = BigInt(value);
    const gasLimit = BigInt(INITIAL_GAS_LIMIT);
    const gasPrice = this.calculateGasPriceInUSD(gasLimit, gasFee);
    state.gasPriceInUSD = gasPrice;
  },
  async refreshConvertValues() {
    const {
      fromAddress,
      toTokenDecimals,
      toTokenAddress
    } = this.getParams();
    if (fromAddress && toTokenAddress && toTokenDecimals && !state.loading) {
      const transaction = await this.getTransaction();
      this.setTransactionDetails(transaction);
    }
  },
  calculateGasPriceInEther(gas, gasPrice) {
    const totalGasCostInWei = gasPrice * gas;
    const totalGasCostInEther = Number(totalGasCostInWei) / 1e18;
    return totalGasCostInEther;
  },
  calculateGasPriceInUSD(gas, gasPrice) {
    const totalGasCostInEther = this.calculateGasPriceInEther(gas, gasPrice);
    const networkPriceInUSD = NumberUtil.bigNumber(state.networkPrice);
    const gasCostInUSD = networkPriceInUSD.multipliedBy(totalGasCostInEther);
    return gasCostInUSD.toNumber();
  },
  calculatePriceImpact(toTokenAmount, gasPriceInUSD) {
    const sourceTokenAmount = state.sourceTokenAmount;
    const sourceTokenPrice = state.sourceTokenPriceInUSD;
    const toTokenPrice = state.toTokenPriceInUSD;
    const totalCostInUSD = NumberUtil.bigNumber(sourceTokenAmount).multipliedBy(sourceTokenPrice).plus(gasPriceInUSD);
    const effectivePricePerToToken = totalCostInUSD.dividedBy(toTokenAmount);
    const priceImpact = effectivePricePerToToken.minus(toTokenPrice).dividedBy(toTokenPrice).multipliedBy(100);
    return priceImpact.toNumber();
  },
  calculateMaxSlippage() {
    const slippageToleranceDecimal = NumberUtil.bigNumber(state.slippage).dividedBy(100);
    const maxSlippageAmount = NumberUtil.multiply(state.sourceTokenAmount, slippageToleranceDecimal);
    return maxSlippageAmount.toNumber();
  },
  async convertTokens() {
    const {
      sourceTokenAddress,
      toTokenAddress
    } = this.getParams();
    if (!sourceTokenAddress || !toTokenAddress) {
      return;
    }
    await this.makeChecks();
  },
  async makeChecks() {
    const {
      toTokenDecimals,
      toTokenAddress
    } = this.getParams();
    if (!toTokenDecimals || !toTokenAddress) {
      return;
    }
    state.loading = true;
    const transaction = await this.getTransaction();
    this.setTransactionDetails(transaction);
    state.loading = false;
  },
  async getTransaction() {
    const {
      fromCaipAddress,
      sourceTokenAddress,
      sourceTokenAmount,
      sourceTokenDecimals
    } = this.getParams();
    if (!fromCaipAddress || !sourceTokenAddress || !sourceTokenAmount || parseFloat(sourceTokenAmount) === 0 || !sourceTokenDecimals) {
      return undefined;
    }
    const hasAllowance = await ConvertApiUtil.fetchConvertAllowance({
      userAddress: fromCaipAddress,
      tokenAddress: sourceTokenAddress,
      sourceTokenAmount,
      sourceTokenDecimals
    });
    let transaction = undefined;
    if (hasAllowance) {
      state.approvalTransaction = undefined;
      transaction = await this.createConvert();
      state.convertTransaction = transaction;
    } else {
      state.convertTransaction = undefined;
      transaction = await this.createTokenAllowance();
      state.approvalTransaction = transaction;
    }
    return transaction;
  },
  getToAmount() {
    const {
      sourceTokenDecimals
    } = this.getParams();
    const decimals = sourceTokenDecimals || 18;
    const multiplier = 10 ** decimals;
    const toTokenConvertedAmount = state.sourceTokenPriceInUSD && state.toTokenPriceInUSD && state.sourceTokenAmount ? NumberUtil.bigNumber(state.sourceTokenAmount).multipliedBy(state.sourceTokenPriceInUSD).dividedBy(state.toTokenPriceInUSD) : NumberUtil.bigNumber(0);
    return toTokenConvertedAmount.multipliedBy(multiplier).toString();
  },
  async createTokenAllowance() {
    const {
      fromCaipAddress,
      fromAddress,
      sourceTokenAddress,
      toTokenAddress
    } = this.getParams();
    if (!fromCaipAddress || !toTokenAddress) {
      return undefined;
    }
    if (!sourceTokenAddress) {
      throw new Error('>>> createTokenAllowance - No source token address found.');
    }
    const response = await BlockchainApiController.generateApproveCalldata({
      projectId: OptionsController.state.projectId,
      from: sourceTokenAddress,
      to: toTokenAddress,
      userAddress: fromCaipAddress
    });
    const gasLimit = await ConnectionController.estimateGas({
      address: fromAddress,
      to: CoreHelperUtil.getPlainAddress(response.tx.to),
      data: response.tx.data
    });
    const toAmount = this.getToAmount();
    const transaction = {
      data: response.tx.data,
      to: CoreHelperUtil.getPlainAddress(response.tx.from),
      gas: gasLimit,
      gasPrice: BigInt(response.tx.eip155.gasPrice),
      value: BigInt(response.tx.value),
      toAmount
    };
    return transaction;
  },
  async sendTransactionForApproval(data) {
    const {
      fromAddress
    } = this.getParams();
    state.transactionLoading = true;
    RouterController.pushTransactionStack({
      view: null,
      goBack: true
    });
    try {
      await ConnectionController.sendTransaction({
        address: fromAddress,
        to: data.to,
        data: data.data,
        value: BigInt(data.value),
        gasPrice: BigInt(data.gasPrice)
      });
      state.approvalTransaction = undefined;
      state.transactionLoading = false;
      this.makeChecks();
    } catch (err) {
      const error = err;
      state.transactionError = error?.shortMessage;
      state.transactionLoading = false;
    }
  },
  async createConvert() {
    const {
      networkAddress,
      fromCaipAddress,
      sourceTokenAddress,
      sourceTokenDecimals,
      sourceTokenAmount,
      toTokenAddress
    } = this.getParams();
    if (!fromCaipAddress || !sourceTokenAmount || !sourceTokenAddress || !toTokenAddress || !sourceTokenDecimals) {
      return undefined;
    }
    try {
      const amount = ConnectionController.parseUnits(sourceTokenAmount, sourceTokenDecimals).toString();
      const response = await BlockchainApiController.generateConvertCalldata({
        projectId: OptionsController.state.projectId,
        userAddress: fromCaipAddress,
        from: sourceTokenAddress,
        to: toTokenAddress,
        amount
      });
      const isSourceTokenIsNetworkToken = sourceTokenAddress === networkAddress;
      const toAmount = this.getToAmount();
      const gas = BigInt(response.tx.eip155.gas);
      const gasPrice = BigInt(response.tx.eip155.gasPrice);
      const transaction = {
        data: response.tx.data,
        to: CoreHelperUtil.getPlainAddress(response.tx.to),
        gas,
        gasPrice,
        value: isSourceTokenIsNetworkToken ? BigInt(amount) : BigInt('0'),
        toAmount
      };
      state.gasPriceInUSD = this.calculateGasPriceInUSD(gas, gasPrice);
      return transaction;
    } catch (error) {
      return undefined;
    }
  },
  async sendTransactionForConvert(data) {
    if (!data) {
      return undefined;
    }
    const {
      fromAddress
    } = this.getParams();
    state.transactionLoading = true;
    RouterController.pushTransactionStack({
      view: 'Account',
      goBack: false,
      onSuccess() {
        ConvertController.resetValues();
      }
    });
    try {
      const transactionHash = await ConnectionController.sendTransaction({
        address: fromAddress,
        to: data.to,
        data: data.data,
        gas: data.gas,
        gasPrice: BigInt(data.gasPrice),
        value: data.value
      });
      state.transactionLoading = false;
      setTimeout(() => {
        this.resetValues();
        this.getMyTokensWithBalance();
      }, 1000);
      return transactionHash;
    } catch (err) {
      const error = err;
      state.transactionError = error?.shortMessage;
      state.transactionLoading = false;
      SnackController.showError(error?.shortMessage || 'Transaction error');
      return undefined;
    }
  },
  getToTokenValues(amountBigInt, decimals) {
    const {
      toTokenAddress
    } = this.getParams();
    if (!toTokenAddress) {
      return {
        toTokenAmount: '0',
        toTokenPriceInUSD: 0
      };
    }
    const toTokenAmount = NumberUtil.bigNumber(amountBigInt).dividedBy(10 ** decimals).toFixed(20);
    const toTokenPrice = state.tokensPriceMap[toTokenAddress] || '0';
    const toTokenPriceInUSD = NumberUtil.bigNumber(toTokenPrice).toNumber();
    return {
      toTokenAmount,
      toTokenPriceInUSD
    };
  },
  isInsufficientNetworkTokenForGas() {
    return NumberUtil.bigNumber(NumberUtil.bigNumber(state.gasPriceInUSD || '0')).isGreaterThan(state.networkBalanceInUSD);
  },
  setTransactionDetails(transaction) {
    const {
      toTokenAddress,
      toTokenDecimals
    } = this.getParams();
    if (!transaction || !toTokenAddress || !toTokenDecimals) {
      return;
    }
    const insufficientNetworkToken = this.isInsufficientNetworkTokenForGas();
    if (insufficientNetworkToken) {
      state.inputError = 'Insufficient balance';
    } else {
      state.inputError = undefined;
    }
    const {
      toTokenAmount,
      toTokenPriceInUSD
    } = this.getToTokenValues(transaction.toAmount, toTokenDecimals);
    state.toTokenAmount = toTokenAmount;
    state.toTokenPriceInUSD = toTokenPriceInUSD;
    state.gasPriceInUSD = this.calculateGasPriceInUSD(transaction.gas, transaction.gasPrice);
    state.priceImpact = this.calculatePriceImpact(state.toTokenAmount, state.gasPriceInUSD);
    state.maxSlippage = this.calculateMaxSlippage();
  }
};
