var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement } from 'lit';
import { property } from 'lit/decorators.js';
import '../../components/wui-icon/index.js';
import '../../components/wui-image/index.js';
import '../../components/wui-loading-spinner/index.js';
import '../../components/wui-text/index.js';
import '../../layout/wui-flex/index.js';
import { elementStyles, resetStyles } from '../../utils/ThemeUtil.js';
import { customElement } from '../../utils/WebComponentsUtil.js';
import '../wui-icon-box/index.js';
import styles from './styles.js';
import { UiHelperUtil } from '../../utils/UiHelperUtil.js';
import { NumberUtil } from '@web3modal/common';
let WuiTokenListItem = class WuiTokenListItem extends LitElement {
  constructor() {
    super(...arguments);
    this.imageSrc = undefined;
    this.name = undefined;
    this.symbol = undefined;
    this.price = undefined;
    this.amount = undefined;
  }
  render() {
    const value = NumberUtil.multiply(this.price, this.amount)?.toFixed(3);
    return html`
      <wui-flex alignItems="center">
        ${this.visualTemplate()}
        <wui-flex flexDirection="column" gap="3xs">
          <wui-flex justifyContent="space-between">
            <wui-text variant="paragraph-500" color="fg-100">${this.name}</wui-text>
            ${value ? html`
                  <wui-text variant="paragraph-500" color="fg-100">
                    $${UiHelperUtil.formatNumberToLocalString(value, 3)}
                  </wui-text>
                ` : null}
          </wui-flex>
          <wui-flex justifyContent="space-between">
            <wui-text variant="small-400" color="fg-200">${this.symbol}</wui-text>
            ${this.amount && html`<wui-text variant="small-400" color="fg-200"
              >${UiHelperUtil.formatNumberToLocalString(this.amount, 4)}</wui-text
            >`}
          </wui-flex>
        </wui-flex>
      </wui-flex>
    `;
  }
  visualTemplate() {
    if (this.imageSrc) {
      return html`<wui-image width="40" height="40" src=${this.imageSrc}></wui-image>`;
    }
    return null;
  }
};
WuiTokenListItem.styles = [resetStyles, elementStyles, styles];
__decorate([property()], WuiTokenListItem.prototype, "imageSrc", void 0);
__decorate([property()], WuiTokenListItem.prototype, "name", void 0);
__decorate([property()], WuiTokenListItem.prototype, "symbol", void 0);
__decorate([property()], WuiTokenListItem.prototype, "price", void 0);
__decorate([property()], WuiTokenListItem.prototype, "amount", void 0);
WuiTokenListItem = __decorate([customElement('wui-token-list-item')], WuiTokenListItem);
export { WuiTokenListItem };
